import React, { useContext, useState } from "react";

const AccountSetupContext = React.createContext();
const AccountSetupUpdateContext = React.createContext();

export function useAccountSetup() {
  return useContext(AccountSetupContext);
}

export function useAccountSetupUpdate() {
  return useContext(AccountSetupUpdateContext);
}

export function AccountSetupProvider({ children }) {
  const [accountData, setAccountData] = useState({
    isSubmitClicked: false,
    securityQuestion: "",
    securityAnswer: "",
    email: "",
    passowrd: "",
    confirmPasswrod: "",
    passwordValidated: false,
    passwordInvalid: false,
    confirmPasswrodInvalid: false,
    chkError: false,
    chkTermSelected: false,
    userFName: "Anjali",
    userLName: "Shelat",
    formIsValid: false,
    iconValidation: {
      iconLength: false,
      iconCase: false,
      iconData: false,
      iconLowerCase: false,
      iconNumber: false,
      iconSpecialChar: false,
      iconFLName: false,
      iconPwdMatch: false,
    },
  });

  const updateAccountData = (property, value) => {
    setAccountData((prevState) => ({ ...prevState, [property]: value }));
  };
  const updatePwEssentialIcons = (property, value) => {
    setAccountData((prevState) => ({
      ...prevState,
      iconValidation: {
        ...prevState.iconValidation,
        [property]: value,
      },
    }));
  };
  const updatePasswordValidation = (pwd, confirmPwd, checkTerms) => {
    const { userFName, userLName, iconValidation, chkTermSelected } = accountData;
    const regExpCapital = /[A-Z]/g;
    const regExpSmall = /[a-z]/g;
    const regExpNumber = /[0-9]/g;
    const specialChar = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/g;

    if (!checkTerms) {
      updateAccountData("chkError", true);
      updateAccountData("formIsValid", false);
    } else {
      updateAccountData("chkError", false);
    }

    // password matching
    if (pwd !== confirmPwd) {
      updateAccountData("passwordInvalid", true);
      updateAccountData("confirmPasswrodInvalid", true);
      updatePwEssentialIcons("iconPwdMatch", false);
      updateAccountData("formIsValid", false);
    } else {
      updateAccountData("confirmPasswrodInvalid", false);
      updatePwEssentialIcons("iconPwdMatch", true);
    }

    // password length
    if (
      pwd?.length < 8 ||
      pwd?.length > 16 ||
      confirmPwd?.length < 8 ||
      confirmPwd?.length > 16
    ) {
      updateAccountData("passwordInvalid", true);
      updateAccountData("confirmPasswrodInvalid", true);
      updatePwEssentialIcons("iconLength", false);
      updateAccountData("formIsValid", false);
    } else {
      updateAccountData("passwordInvalid", false);
      updateAccountData("confirmPasswrodInvalid", false);
      updatePwEssentialIcons("iconLength", true);
    }

    // first or last name check
    if (
      pwd?.includes(userFName) ||
      pwd?.includes(userLName) ||
      confirmPwd?.includes(userFName) ||
      confirmPwd?.includes(userLName)
    ) {
      updateAccountData("passwordInvalid", true);
      updateAccountData("confirmPasswrodInvalid", true);
      updatePwEssentialIcons("iconFLName", false);
      updateAccountData("formIsValid", false);
    } else {
      updateAccountData("passwordInvalid", false);
      updateAccountData("confirmPasswrodInvalid", false);
      updatePwEssentialIcons("iconFLName", true);
    }

    // regex checks
    if (specialChar.test(pwd) && specialChar.test(confirmPwd)) {
      updateAccountData("passwordInvalid", false);
      updateAccountData("confirmPasswrodInvalid", false);
      updatePwEssentialIcons("iconSpecialChar", true);
    } else {
      updateAccountData("passwordInvalid", true);
      updateAccountData("confirmPasswrodInvalid", true);
      updatePwEssentialIcons("iconSpecialChar", false);
      updateAccountData("formIsValid", false);
    }

    if (regExpNumber.test(pwd) && regExpNumber.test(confirmPwd)) {
      updateAccountData("passwordInvalid", false);
      updateAccountData("confirmPasswrodInvalid", false);
      updatePwEssentialIcons("iconNumber", true);
    } else {
      updateAccountData("passwordInvalid", true);
      updateAccountData("confirmPasswrodInvalid", true);
      updatePwEssentialIcons("iconNumber", false);
      updateAccountData("formIsValid", false);
    }

    if (regExpCapital.test(pwd)) {
      updateAccountData("passwordInvalid", false);
      updateAccountData("confirmPasswrodInvalid", false);
      updatePwEssentialIcons("iconCase", true);
    } else {
      updateAccountData("passwordInvalid", true);
      updateAccountData("confirmPasswrodInvalid", true);
      updatePwEssentialIcons("iconCase", false);
      updateAccountData("formIsValid", false);
    }

    if (regExpSmall.test(pwd)) {
      updateAccountData("passwordInvalid", false);
      updateAccountData("confirmPasswrodInvalid", false);
      updatePwEssentialIcons("iconLowerCase", true);
    } else {
      updateAccountData("passwordInvalid", true);
      updateAccountData("confirmPasswrodInvalid", true);
      updatePwEssentialIcons("iconLowerCase", false);
      updateAccountData("formIsValid", false);
    }

    if (
      iconValidation.iconCase &&
      iconValidation.iconLength &&
      iconValidation.iconFLName &&
      iconValidation.iconLowerCase &&
      iconValidation.iconNumber &&
      iconValidation.iconSpecialChar &&
      iconValidation.iconPwdMatch
    ) {
      updateAccountData("passwordInvalid", false);
      updateAccountData("confirmPasswrodInvalid", false);
      updateAccountData("passwordValidated", true);
      // eslint-disable-next-line no-unused-expressions
      if (chkTermSelected) {
        updateAccountData("chkError", false);
      }
      updateAccountData("formIsValid", true);
    }
  };

  return (
    <>
      <AccountSetupContext.Provider
        value={{
          accountData: accountData,
        }}
      >
        <AccountSetupUpdateContext.Provider
          value={{
            accountData,
            updateAccountData: updateAccountData,
            updatePwEssentialIcons: updatePwEssentialIcons,
            updatePasswordValidation: updatePasswordValidation,
          }}
        >
          {children}
        </AccountSetupUpdateContext.Provider>
      </AccountSetupContext.Provider>
    </>
  );
}

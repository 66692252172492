import { PrismCommercialTypography } from "@prism-commercial-ui/react";

const SectionHeader = (props) => {
  const { children, title } = props;
  return (
    <>
      <PrismCommercialTypography
        tag="h2"
        display="functionalL"
        className="uppercase border-b-4 border-borderSubdued"
      >
        {title}
      </PrismCommercialTypography>
      {children}
    </>
  );
};

export default SectionHeader;

import {
  PrismCommercialButton,
  PrismCommercialSingleInput,
  PrismCommercialCheckbox,
} from "@prism-commercial-ui/react";

const CimaForm = () => {
  return (
    <>
      <div className="cima-form">
        <PrismCommercialSingleInput
          name="email"
          label="Email Address"
          id="email"
          errormessage="email does not meet the requirements"
          data-testid="email"
        />
        <div className="mt-5">
          <PrismCommercialSingleInput
            name="password"
            label="Password"
            id="password"
            errormessage="password does not meet the requirements"
            data-testid="password"
          />
        </div>
        <div className="m mt-7">
          <PrismCommercialCheckbox
            id="rememberMe"
            name="rememberMe"
            label="Remember me"
            data-testid="remember-me"
          />
        </div>
        <div className="m mt-10 mb-5">
          <PrismCommercialButton
            title="Sign In"
            btnType="submit"
            type="primary"
          />
        </div>
      </div>
    </>
  );
};

export default CimaForm;

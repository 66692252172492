import SVG from "react-inlinesvg";
import cbLogo from "../assets/cb-logo.svg";
import { Link } from "react-router-dom";

const MasterLayout = (props) => {
  const { hasTopBanner, pageHeadingType } = props;
  let bgClass = "";
  if (pageHeadingType === "CimaLogin") {
    bgClass= "bg-gradient-to-r from-[#0b254e] to-[#154fa0]";
  }
  return (
    <>
      <div
        className={`bg-gray-50 ${
          hasTopBanner ? "" : "overflow-hidden"
        }`}
      >
        {hasTopBanner ? (
          <header className="bg-surfaceNavyPrimary text-white py-4 px-7 flex justify-between">
            {/* <img src={cbLogo} className="w-24 h-8 fill-white" alt="" /> */}
            <Link to={"/"}>
              <SVG src={cbLogo} className="w-24 h-8 fill-white" />
            </Link>
            <Link to={"/"} className="text-white">
              Cancel
            </Link>
          </header>
        ) : (
          ""
        )}
        <div className={`${hasTopBanner ? "mx-auto max-w-7xl mt-12 mb-10 pt-10 pb-14 bg-white px-8" : bgClass}`}>
          {props.children}
        </div>
        <footer className="bg-surfaceNavyPrimary font-tabletBodyLBold text-white py-10 px-11">
          <div className="pb-4 border-neutral-50 border-b flex gap-8">
            <a
              href="http://business.comcast.com"
              target="_blank"
              rel="noreferrer"
            >
              Contact Us
            </a>
            <a
              href="http://business.comcast.com"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            <a
              href="http://business.comcast.com"
              target="_blank"
              rel="noreferrer"
            >
              Do Not Sell My Personal Information
            </a>
            <a
              href="http://business.comcast.com"
              target="_blank"
              rel="noreferrer"
            >
              Visitor Agreement
            </a>
            <a
              href="http://business.comcast.com"
              target="_blank"
              rel="noreferrer"
            >
              Sitemap
            </a>
          </div>
          <div className="font-desktopCaption text-neutral-300 mt-4">
            2022 Comcast Corporation. All Rights Reserved
          </div>
        </footer>
      </div>
    </>
  );
};

export default MasterLayout;

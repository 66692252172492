import MasterLayout from "../containers/MasterLayout";
import { Link } from "react-router-dom";
import { PrismCommercialTypography } from "@prism-commercial-ui/react";
import logo from "../assets/prism-commercial-logo.svg";

export default function Root() {
  return (
    <>
      <MasterLayout>
        <div className="max-w-5xl mx-auto py-4">
          <PrismCommercialTypography tag="h1" display="heading1Bold">
            <img
              className="inline w-16 h-16"
              src={logo}
              alt="Prism Commercial"
            />{" "}
            GDS Demo App
          </PrismCommercialTypography>
          <section>
            <PrismCommercialTypography tag="h2" display="heading5">
              Purpose
            </PrismCommercialTypography>
            <PrismCommercialTypography tag="p" display="bodyM">
              This application is meant to demonstrate the integration and use
              of GDS components in the context of a React application.
            </PrismCommercialTypography>
            <PrismCommercialTypography tag="h3" display="subhead1">
              Example Pages
            </PrismCommercialTypography>
            <ul>
              <li>
                <Link to="/account-setup">Account Registration</Link>
              </li>
              <li>
                <Link to="/autopay">AutoPay</Link>
              </li>
              <li>
                <Link to="/cima-login">Cima Login</Link>
              </li>
            </ul>
          </section>
          <section className="pt-4">
            <PrismCommercialTypography tag="h2" display="subhead1">
              External Information
            </PrismCommercialTypography>
            <ul>
              <li>
                <a
                  href="https://github.comcast.com/BSD-Digital/prism-commercial"
                  target="_blank"
                  rel="noreferrer"
                >
                  Prism Commercial Github Repo
                </a>
              </li>
              <li>
                <a
                  href="https://zeroheight.com/3c3c398d5/p/5181e0-cbgds"
                  target="_blank"
                  rel="noreferrer"
                >
                  Zeroheight Documentation
                </a>
              </li>
              <li>
                <a
                  href="https://prism-commercial.np.digital.business.comcast.com/?path=/story/getting-started-overview--page"
                  target="_blank"
                  rel="noreferrer"
                >
                  Storybook of Components
                </a>
              </li>
            </ul>
          </section>
        </div>
      </MasterLayout>
    </>
  );
}

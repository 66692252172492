import {
  PrismCommercialTypography
} from "@prism-commercial-ui/react";

import MasterLayout from "../containers/MasterLayout";
import ConciseAction from "../components/ConciseAction";
import CimaForm from "../components/CimaForm";
import MyAccountInfo from "../components/MyAccountInfo";
import cbLogoBlack from "../assets/cb-logo-black.svg";

const CimaLoginPage = () => {
  return (
    <>
      <MasterLayout hasTopBanner={false} pageHeadingType="CimaLogin">
        <div className="m-auto max-w-5xl flex justify-between py-8">
          <section className="bg-gray-100 p-12 w-1/2">
            <img
              className="w-36 h-12"
              src={cbLogoBlack}
              alt="Comcast Business"
            />
            <PrismCommercialTypography tag="h2" display="heading8">
              Sign In
            </PrismCommercialTypography>
            <CimaForm />
            <PrismCommercialTypography tag="p" display="bodyS">
              Forgot{" "}
              <a href="https://www.business.comcast.com">email address</a> or{" "}
              <a href="https://www.business.comcast.com">password</a>?
            </PrismCommercialTypography>
          </section>
          <section className="text-white w-1/2 p-4 pl-11 pt-11">
            <ConciseAction
              title="Register for My Account"
              description="View and pay your bill, customize your account preferences, manage your services, and more"
              ctaText="Register Now"
            />
            <div className="mt-12">
              <ConciseAction
                title="Short on time?"
                description="Make an easy and secure bill payment for a single account without signing in."
                ctaText="Try Quick Pay"
              />
            </div>
            <div className="mt-12">
              <ConciseAction
                title="Xfinity Customer?"
                description="Xfinity customers, sign in to your account."
              />
            </div>
          </section>
        </div>
        <MyAccountInfo />
      </MasterLayout>
    </>
  );
};

export default CimaLoginPage;

import {
  PrismCommercialTypography,
  PrismCommercialButton,
} from "@prism-commercial-ui/react";

const ConciseAction = (props) => {
  const { title, description, ctaText } = props;
  return (
    <>
      <div className="concise-action">
        <PrismCommercialTypography tag="h2" display="heading7">
          {title}
        </PrismCommercialTypography>
        <PrismCommercialTypography tag="p" display="bodyL">
          {description}
        </PrismCommercialTypography>
        {ctaText ? (
          <PrismCommercialButton
            title={ctaText}
            className="submit-btn cursor-pointer"
            btnType="submit"
            value="submit"
            size="sm"
            type="secondary"
            variant="outline"
          ></PrismCommercialButton>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default ConciseAction;

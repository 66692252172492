import {
  PrismCommercialTypography,
  PrismCommercialFlag,
} from "@prism-commercial-ui/react";

const StatusRow = (props) => {
  const { title, badgeText, isFirstRow, children } = props;
  return (
    <>
      <div
        className={`flex items-center justify-between ${
          isFirstRow ? "mt-5" : "pt-2 border-t-2 border-borderSubdued"
        }`}
      >
        <PrismCommercialTypography tag="span" display="bodyLBold">
          {title}
        </PrismCommercialTypography>
        <PrismCommercialFlag
          text="Password Created"
          variant="outline"
          type="success-green"
          icon="icon-left"
          iconType="check"
        >
          <prism-commercial-icon slot="icon-left" type="check" size="xs" />
          {badgeText}
        </PrismCommercialFlag>
      </div>
      <div className="mt-6">{children}</div>
    </>
  );
};

export default StatusRow;

import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Root from "./routes/root";
import ErrorPage from "./pages/ErrorPage";
import AccountSetup from "./pages/AccountSetup.jsx";
import Autopay from "./pages/Autopay";
import CimaLoginPage from "./pages/CimaLogin";
import { AccountSetupProvider } from "./context/AccountSetupContext";

import {
  BatchSpanProcessor,
} from "@opentelemetry/sdk-trace-web";
import { Resource } from "@opentelemetry/resources";
import { CompositePropagator, W3CBaggagePropagator, W3CTraceContextPropagator } from '@opentelemetry/core';
import { SemanticResourceAttributes } from "@opentelemetry/semantic-conventions";
import { WebTracerProvider } from "@opentelemetry/sdk-trace-web";
import { DocumentLoadInstrumentation } from "@opentelemetry/instrumentation-document-load";
import { XMLHttpRequestInstrumentation } from "@opentelemetry/instrumentation-xml-http-request";
import { registerInstrumentations } from "@opentelemetry/instrumentation";
import { ZoneContextManager } from "@opentelemetry/context-zone";
import { OTLPTraceExporter } from "@opentelemetry/exporter-trace-otlp-http";
import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web';

const tracer = new WebTracerProvider();

tracer.resource = new Resource({
  [SemanticResourceAttributes.SERVICE_NAME]: "gds-demo-ui",
  [SemanticResourceAttributes.SERVICE_NAMESPACE]: "bsd-digital",
  [SemanticResourceAttributes.SERVICE_VERSION]: "1.0.1",
  [SemanticResourceAttributes.TELEMETRY_SDK_LANGUAGE]: "nodejs",
});

console.log("EXPORTER_OTLP_ENDPOINT = ", process.env.OTEL_EXPORTER_OTLP_ENDPOINT);

tracer.addSpanProcessor(new BatchSpanProcessor(new OTLPTraceExporter(
  {
    url: "https://otlp-gateway.np.api-business.comcast.com:443/v1/traces",
    Headers:{}
  })));

tracer.register({
  // Changing default contextManager to use ZoneContextManager - supports asynchronous operations - optional
  contextManager: new ZoneContextManager(),
  propagator: new CompositePropagator({
    propagators: [new W3CBaggagePropagator(), new W3CTraceContextPropagator()],
  })
});

// Registering instrumentations
registerInstrumentations({
  instrumentations: [
    new DocumentLoadInstrumentation(),
    new XMLHttpRequestInstrumentation(),
    getWebAutoInstrumentations({
      '@opentelemetry/instrumentation-fetch': {
        propagateTraceHeaderCorsUrls: /.*/,
        clearTimingResources: true,
        applyCustomAttributesOnSpan(span) {
          span.setAttribute('app.synthetic_request', 'false');
        },
      },
    })
  ]
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/account-setup",
    element: <AccountSetup />,
  },
  {
    path: "autopay",
    element: <Autopay />,
  },
  {
    path: "cima-login",
    element: <CimaLoginPage />,
  }
]);

// const span = tracer.startSpan('my-span');

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {/* <App /> */}
    <AccountSetupProvider>
      <RouterProvider router={router} />
    </AccountSetupProvider>
  </React.StrictMode>
);

// span.end();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { useAccountSetup } from "../context/AccountSetupContext";
import {
  PrismCommercialIcon,
  PrismCommercialTypography,
} from "@prism-commercial-ui/react";

const PasswordEssentials = () => {
  const { accountData } = useAccountSetup();
  const { isSubmitClicked, iconValidation } = accountData;
  return (
    <>
      <div className="col password-essentials bg-surfaceGreyDefault px-5 py-3">
        <PrismCommercialTypography tag="h3" display="functionalM" data-testid="pwe-title">
          Password Essentials
        </PrismCommercialTypography>
        <div className="flex">
          <PrismCommercialIcon
            type={
              isSubmitClicked
                ? iconValidation.iconLength
                  ? "complete-filled"
                  : "failure-filled"
                : "minus-circle-filled"
            }
            data-testid="pwe-length-icon"
            name="iconLength"
            className={
              isSubmitClicked
                ? iconValidation.iconLength
                  ? "text-iconSuccess"
                  : "text-iconCritical"
                : ""
            }
          ></PrismCommercialIcon>
          <PrismCommercialTypography
            tag="label"
            display="bodyS"
            className="m-0"
            data-testid="pwe-length-label"
          >
            Length should be 8 to 16 characters
          </PrismCommercialTypography>
        </div>

        <div className="flex">
          <PrismCommercialIcon
            type={
              isSubmitClicked
                ? iconValidation.iconCase
                  ? "complete-filled"
                  : "failure-filled"
                : "minus-circle-filled"
            }
            name="iconCase"
            data-testid="pwe-upper-icon"
            className={
              isSubmitClicked
                ? iconValidation.iconCase
                  ? "text-iconSuccess"
                  : "text-iconCritical"
                : ""
            }
          ></PrismCommercialIcon>
          <PrismCommercialTypography
            tag="label"
            display="bodyS"
            className="m-0"
            data-testid="pwe-upper-label"
          >
            At least one uppercase letter A-Z
          </PrismCommercialTypography>
        </div>
        <div className="flex">
          <PrismCommercialIcon
            type={
              isSubmitClicked
                ? iconValidation.iconLowerCase
                  ? "complete-filled"
                  : "failure-filled"
                : "minus-circle-filled"
            }
            className={
              isSubmitClicked
                ? iconValidation.iconLowerCase
                  ? "text-iconSuccess"
                  : "text-iconCritical"
                : ""
            }
            name="iconLowerCase"
            data-testid="pwe-lower-icon"
          ></PrismCommercialIcon>
          <PrismCommercialTypography
            tag="label"
            display="bodyS"
            className="m-0"
            data-testid="pwe-lower-label"
          >
            At least one lowercase letter a-z
          </PrismCommercialTypography>
        </div>
        <div className="flex">
          <PrismCommercialIcon
            type={
              isSubmitClicked
                ? iconValidation.iconNumber
                  ? "complete-filled"
                  : "failure-filled"
                : "minus-circle-filled"
            }
            className={
              isSubmitClicked
                ? iconValidation.iconNumber
                  ? "text-iconSuccess"
                  : "text-iconCritical"
                : ""
            }
            name="iconNumber"
            data-testid="pwe-number-icon"
          ></PrismCommercialIcon>
          <PrismCommercialTypography
            tag="label"
            display="bodyS"
            className="m-0"
            data-testid="pwe-number-label"
          >
            At least one number
          </PrismCommercialTypography>
        </div>
        <div className="flex">
          <PrismCommercialIcon
            type={
              isSubmitClicked
                ? iconValidation.iconSpecialChar
                  ? "complete-filled"
                  : "failure-filled"
                : "minus-circle-filled"
            }
            className={
              isSubmitClicked
                ? iconValidation.iconSpecialChar
                  ? "text-iconSuccess"
                  : "text-iconCritical"
                : ""
            }
            name="iconSpecialChar"
            data-testid="pwe-specialchar-icon"
          ></PrismCommercialIcon>
          <PrismCommercialTypography
            tag="label"
            display="bodyS"
            className="m-0"
            data-testid="pwe-specialchar-label"
          >
            At least one special character
          </PrismCommercialTypography>
        </div>
        <div className="flex">
          <PrismCommercialIcon
            type={
              isSubmitClicked
                ? iconValidation.iconFLName
                  ? "complete-filled"
                  : "failure-filled"
                : "minus-circle-filled"
            }
            className={
              isSubmitClicked
                ? iconValidation.iconFLName
                  ? "text-iconSuccess"
                  : "text-iconCritical"
                : ""
            }
            name="iconFLName"
            data-testid="pwe-name-icon"
          ></PrismCommercialIcon>
          <PrismCommercialTypography
            tag="label"
            display="bodyS"
            className="m-0"
            data-testid="pwe-name-label"
          >
            Cannot contain your first or last name
          </PrismCommercialTypography>
        </div>
        <div className="flex">
          <PrismCommercialIcon
            type={
              isSubmitClicked
                ? iconValidation.iconPwdMatch
                  ? "complete-filled"
                  : "failure-filled"
                : "minus-circle-filled"
            }
            name="iconPwdMatch"
            data-testid="pwe-match-icon"
            className={
              isSubmitClicked
                ? iconValidation.iconPwdMatch
                  ? "text-iconSuccess"
                  : "text-iconCritical"
                : ""
            }
          ></PrismCommercialIcon>
          <PrismCommercialTypography
            tag="label"
            display="bodyS"
            className="m-0"
            data-testid="pwe-match-label"
          >
            Your passwords must match
          </PrismCommercialTypography>
        </div>
      </div>
    </>
  );
};

export default PasswordEssentials;

import axios from "axios";
import { useEffect, useState } from "react";
import MasterLayout from "../containers/MasterLayout";
import PasswordRow from "../containers/PasswordRow";
import PasswordFormFields from "../components/PasswordFormFields";
import {
  PrismCommercialButton,
  PrismCommercialTypography,
  PrismCommercialSpinner
} from "@prism-commercial-ui/react";
import PasswordEssentials from "../components/PasswordEssentials";
import {
  useAccountSetup,
  useAccountSetupUpdate,
} from "../context/AccountSetupContext";

const AccountSetup = () => {
  const [data, setData] = useState(null);
  const { accountData } = useAccountSetup();
  const { updateAccountData, updatePasswordValidation } = useAccountSetupUpdate();
  const {
    userFName,
    userLName,
    chkTermSelected,
    formIsValid
  } = accountData;

  const handleSubmit = (event) => {
    event.preventDefault();
    updateAccountData("isSubmitClicked", true);
    const password = document.getElementById("password").value;
    const confirmPassword = document.getElementById("confirmPassword").value;
    let chkValue = chkTermSelected;
    updatePasswordValidation(password, confirmPassword, chkValue);
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get("https://swapi.dev/api/people/1/");
      setData(result);
    }

    if ( formIsValid ) {
      fetchData();
    }
  }, [formIsValid])

  return (
    <MasterLayout hasTopBanner={true}>
      <div>
        <form>
          <PrismCommercialTypography tag="h1" display="functionalL">
            SET UP MY ACCOUNT
          </PrismCommercialTypography>
          <hr></hr>
          <PrismCommercialTypography tag="p" display="bodyL">
            My Account lets you manage your installation appointment, download a
            copy of your contracts, manage services, and more. Just create a
            password to get started.
          </PrismCommercialTypography>
          <section className="flex justify-between">
            <div className="w-1/2">
              <PrismCommercialTypography tag="h1" display="label">
                Name
              </PrismCommercialTypography>
              <PrismCommercialTypography
                tag="p"
                display="bodyL"
                className="label-text"
                id="name"
              >
                {userFName + " " + userLName}
              </PrismCommercialTypography>
            </div>
            <div className="w-1/2">
              <PrismCommercialTypography tag="h1" display="label">
                Email Address
              </PrismCommercialTypography>
              <PrismCommercialTypography
                tag="p"
                display="bodyL"
                className="label-text"
                data-testid="email-address"
              >
                Anjali.shelat@comcast.com
              </PrismCommercialTypography>
            </div>
          </section>
          <section className="border-borderSubdued border p-8 flex">
            <div className="w-1/2" data-testid="set-password">
              <PasswordFormFields />
              <PasswordRow>
                <PrismCommercialButton
                  title="Set Password"
                  className="submit-btn cursor-pointer"
                  btnType="submit"
                  value="submit"
                  onClick={handleSubmit}
                  size="sm"
                ></PrismCommercialButton>
                {data ? `` : <PrismCommercialSpinner size="md" type="branded" />}
              </PasswordRow>
            </div>
            <div className="w-1/2">
              <PasswordEssentials />
            </div>
          </section>
        </form>
        <PrismCommercialTypography
          tag="h1"
          display="functionalL"
          className="label-text label-margin"
        >
          ENROLL IN AUTOPAY AND PAPERLESS BILLING
        </PrismCommercialTypography>
        <hr></hr>

        <PrismCommercialTypography
          tag="h1"
          display="functionalL"
          className="label-text label-margin"
        >
          VERIFY MY ACCOUNT
        </PrismCommercialTypography>
        <hr></hr>
      </div>
    </MasterLayout>
  );
};

export default AccountSetup;

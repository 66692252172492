import {
  PrismCommercialTypography,
  PrismCommercialButton,
  PrismCommercialFlag,
  PrismCommercialSingleInput,
} from "@prism-commercial-ui/react";

import MasterLayout from "../containers/MasterLayout";
import SectionHeader from "../components/SectionHeader";
import StatusRow from "../components/StatusRow";
import NetServiceButton from "../components/NetServiceButton";

const Autopay = () => {
  return (
    <>
      <MasterLayout hasTopBanner={true}>
        <SectionHeader title="Set up My Account">
          <StatusRow
            title="My Account"
            badgeText="Password Created"
            isFirstRow={true}
          />
        </SectionHeader>
        <div className="mt-16">
          <SectionHeader title="Enroll in Auto Pay and Paperless Billing">
            <StatusRow
              title="Auto Pay"
              badgeText="Enrolled"
              isFirstRow={true}
            />
            <StatusRow title="Paperless Billing" badgeText="Enrolled">
              <PrismCommercialFlag
                text="Password Created"
                variant="filled"
                type="success-green"
                icon="icon-left"
                size="normal"
              >
                <prism-commercial-icon
                  slot="icon-left"
                  type="complete-filled"
                  size="xs"
                />
                Discount Activated
              </PrismCommercialFlag>
            </StatusRow>
          </SectionHeader>
        </div>
        <div className="mt-16">
          <SectionHeader title="Verify My Account">
            <PrismCommercialTypography tag="p" display="bodyM">
              Check your inbox. We sent a verification code to <strong>nathan.roberts@ctownsend.com.</strong><br/>You can always update your email settings later in My Account.
            </PrismCommercialTypography>
            <PrismCommercialSingleInput label="Enter Verification Code" placeholder="6-digit verification code" />
            <PrismCommercialTypography tag="p" display="bodyM">
              <a href="http://www.business.comcast.com">Resend code</a> Your new code will expire in 15 mins
            </PrismCommercialTypography>
            <PrismCommercialButton
              type="primary"
              size="md"
              title="Verify and Sign In"
              className="mt-7"
            />
          </SectionHeader>
          <NetServiceButton />
        </div>
      </MasterLayout>
    </>
  );
};

export default Autopay;

import React, { useState } from "react";

function NetServiceButton() {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    const response = await fetch(
      "https://dev.demo.np.api-business.comcast.com/netservice-api/demo"
    );
    const json = await response.json();
    setData(json);
    setIsLoading(false);
  };

  return (
    <>
      <button onClick={fetchData}>
        {isLoading ? "Loading..." : "Fetch Net Service Data"}
      </button>
      <span>{data}</span>
    </>
  );
}

export default NetServiceButton;

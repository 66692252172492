import { PrismCommercialTypography } from "@prism-commercial-ui/react";
import { Link } from "react-router-dom";

import ConciseAction from "./ConciseAction";
import appleStore from "../assets/AppStoreBadge.svg";
import googlePlay from "../assets/GooglePlayBadge.svg";

const MyAccountInfo = (props) => {
  return (
    <>
      <div className="myaccount-info bg-gray-50 py-16 px-12">
        <div max-w-7xl>
          <span className="text-center">
            <PrismCommercialTypography tag="h2" display="heading4">
              Get the most out of My Account
            </PrismCommercialTypography>
          </span>
          <div className="flex justify-center">
            <div className="max-w-xs border-0 border-r border-r-gray-300 pr-5">
              <ConciseAction
                title="Download the App"
                description="Get anytime, anywhere account access with the Comcast Business App."
              />
              <div>
                <img className="inline" src={appleStore} alt="Apple Store" />
                <img className="inline" src={googlePlay} alt="Google Play" />
              </div>
            </div>
            <div className="max-w-xs border-0 border-r border-r-gray-300 pr-5 pl-6">
              <ConciseAction
                title="Pay Your Bill Online"
                description="Make and schedule payments, customize your billing options, and more."
              />
            </div>
            <div className="max-w-xs border-0 border-r border-r-gray-300  pr-5 pl-6">
              <ConciseAction
                title="Manage Your Account"
                description="View and pay your bill, customize your account preferences, manage your services, and more."
              />
            </div>
            <div className="max-w-xs pl-6">
              <ConciseAction
                title="Access Cloud Solutions"
                description="Boost productivity with a suite of apps designed to keep your business moving forward."
              />
            </div>
          </div>
        </div>
      </div>
      <div className="myaccount-info bg-gray-100 pt-10 pb-12">
        <div max-w-7xl>
          <span className="text-center">
            <PrismCommercialTypography tag="h3" display="heading6">
              Not yet a Comcast Business customer?
            </PrismCommercialTypography>
            <div className="text-center"><Link to="https://business.comcast.com/?noredirect">Find the best solution for your business</Link></div>
          </span>
        </div>
      </div>
    </>
  );
};

export default MyAccountInfo;

import PasswordRow from "../containers/PasswordRow";
import { PrismCommercialSingleInput, PrismCommercialCheckbox } from "@prism-commercial-ui/react";
import { useAccountSetup, useAccountSetupUpdate } from "../context/AccountSetupContext";

const PasswordFormFields = () => {
  const { accountData } = useAccountSetup();
  const {
    passwordInvalid,
    passwordValidated,
    confirmPasswrodInvalid,
    chkError,
    chkTermSelected
  } = accountData;
  const { updateAccountData } = useAccountSetupUpdate();
  const chkSelected = () => {
    updateAccountData("chkTermSelected", !chkTermSelected);
  };
  return (
    <>
      <PasswordRow isFirstRow={true}>
        <PrismCommercialSingleInput
          name="password"
          label="Password"
          id="password"
          error={passwordInvalid}
          validated={passwordValidated}
          errormessage="Password does not meet the requirements"
          data-testid="password"
        />
      </PasswordRow>
      <PasswordRow>
        <PrismCommercialSingleInput
          name="confirmPassword"
          label="Confirm password"
          id="confirmPassword"
          validated={passwordValidated}
          error={confirmPasswrodInvalid}
          errormessage="Password do not match"
          data-testid="confirm-password"
        />
      </PasswordRow>
      <PasswordRow>
        <label className="label-css block">Select a Security Question</label>
        <select
          id="securityQuestion"
          name="securityQuestion"
          className="dropdown-css border border-borderDefault p-3 rounded-sm"
        >
          <option value="What street did you grow up on?">
            What street did you grow up on?{" "}
          </option>
          <option value="What is your favourite car?">
            What is your favourite car?
          </option>
          <option value="What is your birth city?">
            What is your birth city?{" "}
          </option>
          <option value=" What is your fav destination for a vacation?">
            What is your fav destination for a vacation?{" "}
          </option>
        </select>
      </PasswordRow>
      <PasswordRow>
        <PrismCommercialSingleInput
          id="securityAnswer"
          name="securityAnswer"
          label="Security Question Answer"
        />
      </PasswordRow>
      <PasswordRow>
        <PrismCommercialCheckbox
          id="checkTerms"
          name="checkTerms"
          label=" Yes, I have read and agree to
                        The Terms of Service"
          alert="Must Accept the terms of service"
          onClick={chkSelected}
          error={chkError}
          errorshow={chkError}
          data-testid="check-terms"
        />
      </PasswordRow>
    </>
  );
};

export default PasswordFormFields;
